// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../shared/utils/I18n.bs.js");
var Icon = require("../../shared/Icon.bs.js");
var User = require("../../shared/types/User.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Avatar = require("../../shared/Avatar.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var DateFns = require("../../shared/utils/DateFns.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Tooltip = require("../../shared/components/Tooltip.bs.js");
var Js_option = require("rescript/lib/js/js_option.js");
var ReactUtils = require("../../shared/utils/ReactUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var GraphqlQuery = require("../../shared/utils/GraphqlQuery.bs.js");
var Notifications__Entry = require("../types/Notifications__Entry.bs.js");

function str(prim) {
  return prim;
}

((require("./Notifications__EntryCard.css")));

var partial_arg = "components.Notifications__List";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var ppx_printed_query = "mutation MarkNotificationMutation($notificationId: ID!)  {\nmarkNotification(notificationId: $notificationId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "markNotification");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type MarkNotificationPayload is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field markNotification on type Mutation is missing");
  }
  return {
          markNotification: tmp
        };
}

function make(notificationId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "notificationId",
                    notificationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var notificationId = variables.notificationId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "notificationId",
                    notificationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(notificationId, param) {
  return Js_dict.fromArray([[
                  "notificationId",
                  notificationId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, notificationId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "notificationId",
                      notificationId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var MarkNotificationQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function markNotification(notificationId, setSaving, markNotificationCB, $$event) {
  $$event.preventDefault();
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  GraphqlQuery.sendQuery(undefined, make(notificationId, undefined)).then(function (response) {
          if (response.markNotification.success) {
            Curry._1(setSaving, (function (param) {
                    return false;
                  }));
            Curry._1(markNotificationCB, notificationId);
          } else {
            Curry._1(setSaving, (function (param) {
                    return false;
                  }));
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(setSaving, (function (param) {
                return false;
              }));
        return Promise.resolve(undefined);
      });
  
}

function avatarClasses(size) {
  var mdSize = size[1];
  var defaultSize = size[0];
  return "w-" + (defaultSize + (" h-" + (defaultSize + (" md:w-" + (mdSize + (" md:h-" + (mdSize + " text-xs border border-white rounded-full overflow-hidden flex-shrink-0 object-cover")))))));
}

function avatar(sizeOpt, avatarUrl, name) {
  var size = sizeOpt !== undefined ? sizeOpt : [
      "10",
      "10"
    ];
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses(size),
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses(size)
              });
  }
}

function Notifications__EntryCard(Props) {
  var entry = Props.entry;
  var markNotificationCB = Props.markNotificationCB;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var _readAt = Notifications__Entry.readAt(entry);
  var actor = Notifications__Entry.actor(entry);
  var partial_arg = Notifications__Entry.id(entry);
  return React.createElement("div", {
              key: Notifications__Entry.id(entry),
              "aria-label": "Notification " + Notifications__Entry.id(entry),
              className: "notifications__entry-card block relative py-5 px-4 lg:px-8 text-sm font-semibold hover:bg-gray-200 focus-within:bg-gray-200 transition ease-in-out duration-150 " + (
                _readAt !== undefined ? "notifications__entry-card--read text-gray-700" : "notifications__entry-card--unread"
              )
            }, React.createElement("div", {
                  className: "flex justify-between items-center"
                }, React.createElement("div", {
                      className: "flex-1 flex items-center relative"
                    }, React.createElement("div", {
                          className: "flex-shrink-0 inline-block relative"
                        }, actor !== undefined ? avatar(undefined, User.avatarUrl(actor), User.name(actor)) : null, ReactUtils.nullUnless(React.createElement("span", {
                                  className: "notifications__entry-unread-dot absolute top-0 flex justify-center h-full items-center"
                                }, React.createElement("span", {
                                      className: "block h-1 w-1 rounded-full shadow-solid bg-primary-400"
                                    })), Belt_Option.isNone(Notifications__Entry.readAt(entry)))), Belt_Option.isSome(Notifications__Entry.notifiableId(entry)) ? React.createElement("a", {
                            className: "ml-4 outline-none",
                            href: "/notifications/" + Notifications__Entry.id(entry)
                          }, Notifications__Entry.message(entry)) : React.createElement("div", {
                            className: "ml-4"
                          }, Notifications__Entry.message(entry))), React.createElement("div", {
                      className: "flex-shrink-0"
                    }, React.createElement("span", {
                          className: "notifications__entry-card-time block text-xs text-gray-800"
                        }, React.createElement("span", {
                              className: "hidden md:inline-block md:pl-4"
                            }, DateFns.format(Notifications__Entry.createdAt(entry), "HH:mm"))), React.createElement("div", {
                          className: "opacity-0 notifications__entry-card-buttons absolute top-0 bottom-0 right-0 flex items-center pl-4 pr-4 md:pr-8 transition ease-in-out duration-150"
                        }, ReactUtils.nullIf(React.createElement(Tooltip.make, {
                                  tip: "Mark as Read",
                                  position: "Left",
                                  children: React.createElement("button", {
                                        "aria-label": "Mark as read",
                                        className: "flex justify-center items-center w-8 h-8 font-semibold p-2 md:py-1 border border-gray-400 rounded text-sm bg-white text-gray-700 hover:text-primary-500 hover:border-primary-400 hover:bg-gray-200 hover:shadow-md focus:outline-none focus:text-primary-500 focus:border-primary-400 focus:bg-gray-200 focus:shadow-md transition ease-in-out duration-150",
                                        title: "Mark as Read",
                                        disabled: match[0],
                                        onClick: (function (param) {
                                            return markNotification(partial_arg, setSaving, markNotificationCB, param);
                                          })
                                      }, React.createElement(Icon.make, {
                                            className: "if i-check-solid"
                                          }))
                                }), Belt_Option.isSome(Notifications__Entry.readAt(entry)))))));
}

var make$1 = Notifications__EntryCard;

exports.str = str;
exports.t = t;
exports.MarkNotificationQuery = MarkNotificationQuery;
exports.markNotification = markNotification;
exports.avatarClasses = avatarClasses;
exports.avatar = avatar;
exports.make = make$1;
/*  Not a pure module */
